<template v-slot="scope">
   <!-- 查看/编辑 :icon="Edit"-->
   <el-button :class="theme === 'white' ? 'menu-white' : theme === 'black' ? 'menu-black' : 'menu-white'" type="primary"
      native-type="button" style="width: 35px" v-DB:500="() => handleAddOrEditDialogValue(scope.row)"
      :disabled="hasAuthority(tableMenuAuthorityDetail) && hasAuthority(tableMenuAuthorityEdit)">
      {{
         hasAuthority(tableMenuAuthorityEdit) === false ? '编辑' : hasAuthority(tableMenuAuthorityDetail) === false ? '查看' :
            '无'
      }}
   </el-button>
   <!-- 删除 -->
   <el-button :class="theme === 'white' ? 'menu-white' : theme === 'black' ? 'menu-black' : 'menu-white'"
      v-DB:200="() => handleDelete(scope.row)" type="danger" :icon="Delete" native-type="button" style="width: 35px"
      :disabled="hasAuthority(tableMenuAuthorityDelete)" />
</template>

<script setup>
import { Search, Delete, DocumentAdd, Edit, Tools, RefreshRight } from '@element-plus/icons-vue'
import requestUtil, { getServerUrl } from "@/util/request";
import { computed, defineEmits, defineProps, ref, watch } from "vue";
import { ElMessage, ElMessageBox } from 'element-plus'
import store from "@/store";


// 查看权限判断
const tableMenuAuthorityDetail = computed(() => {
   if (props.port != null) {
      let newAuthrity = props.port + '/detail';
      return newAuthrity.replace(/^\//, '').replace(/\//g, ':');
   } else return ''
})
// 编辑权限判断
const tableMenuAuthorityEdit = computed(() => {
   if (props.port != null) {
      let newAuthrity = props.port + '/edit';
      return newAuthrity.replace(/^\//, '').replace(/\//g, ':');
   } else return ''
})
// 删除权限判断
const tableMenuAuthorityDelete = computed(() => {
   if (props.port != null) {
      let newAuthrity = props.port + '/delete';
      return newAuthrity.replace(/^\//, '').replace(/\//g, ':');
   } else return ''
})
const emits = defineEmits(['initTableList', 'edit', 'delete',])
// 编辑
const handleAddOrEditDialogValue = (row) => {
   emits("edit", row)
}
// 删除
const handleDelete = async (row) => {
   ElMessageBox.confirm(`您确定要执行<span style="color: red;">删除</span>操作吗？`, '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      dangerouslyUseHTMLString: true
   }).then(() => {
      emits("delete", row);
   }).catch(() => {
   });
}
const props = defineProps({
   port: {
      type: String,
      default: "",
   },
   scope: {
      type: Object,
      required: true
   }
})

const theme = ref(store.state.theme)
// 上侧 tab处切换菜单 实时左侧tab更新
watch(
   () => [store.state.theme],
   () => {
      theme.value = store.state.theme;
   }, { deep: true, immediate: true }
)
</script>

<style lang="scss" scoped>
.menu-white {
   padding: 0px !important;
   margin: 5px 5px !important;
   white-space: nowrap;
}

.menu-black {
   padding: 0px !important;
   margin: 5px 5px !important;
   white-space: nowrap;
   background-color: black;
   color: white;
   border: 1px solid white;
}
</style>