<template>
  <div class="wrapper" :style="{ zIndex: tableColumeZIndex }" pre-wrap>
    <!-- 控制显示隐藏 -->
    <el-card :style="{ opacity: visible ? '1' : '0' }">
      <div slot="header" class="header">
        <span>请选择展示列表</span>
      </div>
      <div class="body">
        <!--全选-->
        <el-checkbox v-model="checkAll" @change="selectAll" class="checkAll">全选
        </el-checkbox>
        <!--列表数据-->
        <el-checkbox-group v-model="checkList" class="checkList" @change="handleCheckChange">
          <el-checkbox v-for="item in allList" :key="item.prop" :label="item.prop">
            {{ item.label }}
          </el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="footer">
        <el-button type="primary" size="small" :disabled="checkList.length < 1" v-DB:500="confirm">确定</el-button>
        <el-button type="danger" size="small" v-DB:500="reset">重置</el-button>
        <el-button type="warning" size="small" v-DB:500="cancel">取消</el-button>
      </div>
    </el-card>
  </div>
</template>
<script setup>
import {computed, defineEmits, defineProps, ref, watch} from "vue";

const name = ref("KeySetting")
const checkAll = ref(false) // 全选按钮选中状态
const checkList = ref([]) // 子组件回显
const oldList = ref([]) // 用于返回事件 父级初始传入的序号
const emits = defineEmits(['update:visible',])
const props = defineProps({
  // 组件开关
  visible: {
    type: Boolean,
    default: false,
  },
  // 所有字段,用于子组件显示所有字段
  allList: {
    type: Array,
    default: () => [],
    required: true,
  },
  // 父组件选中字段，用于子组件回显列表
  checkList: {
    type: Array,
    default: [],
    required: true,
  },
})
watch(
    // 监听到开关变化
    () => props.visible,
    () => {
      // 手动开启
      if (props.visible == true) {
        oldList.value = props.checkList;  // 保留最近一次选择列表 取消时用，以及checkAllStatus第二种情况用
        checkList.value = props.checkList; // 回显
        checkAllStatus(); // 全选按钮判定
      } else if (props.visible == false) {
        // 设置z-index
        checkAllStatus(); // 全选按钮判定
      }
    }
)
// 监听属性变化 动态赋值 z-index 浮动层
let tableColumeZIndex = computed(() => {
  if (props.visible == true) {
    return 999;
  } else if (props.visible == false) {
    return -1;
  }
})

// 全选按钮变化事件
const selectAll = (value) => {
  // console.log(value)
  if (value == true) {
    checkList.value = []; //先清空，否则会导致列表叠加
    props.allList.forEach((item) => {
      checkList.value.push(item.prop);
    });
  } else if (value == false) {
    checkList.value = [];
  }
}
// 每个单选框事件
const handleCheckChange = (value) => {
  // 当选中数组长度等于全部长度 全选变勾选状态 true
  const checkCount = value.length;
  checkAll.value = checkCount === props.allList.length;
}

// 点击确定
const confirm = () => {
  oldList.value = checkList.value;
  emits('confirm', checkList.value)
}
// 点击重置
const reset = () => {
  checkList.value = [];
  checkAllStatus();
}
// 点击取消
const cancel = () => {
  emits('cancel', oldList.value)
}
// 判断全选按钮状态
const checkAllStatus = () => {
  // 点击按钮时 此时checkList.value已初始赋值为回显值
  if (props.visible == true) {
    if (checkList.value.length == props.allList.length) {
      checkAll.value = true;
    } else {
      checkAll.value = false;
    }
  } else if (props.visible == false) {
    /**
     *  review (确认)导致监听false，先执行(确认)中的(二次oldList赋值)，在执行关闭,开启监听，全选按钮赋值。
     *  2 (取消)和(手动点击原按钮关闭->不执行确认) 导致false,在监听处初始化保留原数据为oldList，结束则还原数组
     */
    checkList.value = oldList.value; // 异常情况还原
  }
}
</script>
<style lang="scss" scoped>
//竖向布局
.wrapper {
  position: fixed;
  width: 200px;
  height: 210px;
  top: 160px;
  right: 22px;
  z-index: -1;

  @media screen and (min-height: 950px) and (max-height: 990px) {
    top: 100px;
  }
  // 适配浏览器全屏模式下的上下边距
  @media screen and (min-height: 1070px) {
    top: 150px;
  }

  .el-card {
    width: 100%;
    height: 100%;
    overflow: auto;
    //position: absolute;
    :deep .el-card__body {
    }

    .header {
      height: 20% !important;
      color: red;
      font-weight: 600;
      min-width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 0px 0 10px 0;
    }

    .body {
      max-height: 120px !important;
      overflow: auto;
      width: 100%;
      .el-checkbox-group {
        max-height: 70% !important;
        //border: solid red 1px;
        overflow: auto;
        display: flex;
        flex-wrap: wrap; /* 如果容器宽度不足以容纳所有元素，则自动换行 */
        .el-checkbox {
          flex-basis: 100%; /* 每个元素占据一行 */
        }
      }
    }

    .footer {
      min-width: 100px;
      padding: 10px 0 0 0;
      display: flex;
      justify-content: center;
      align-items: center;
      //height: auto;
      //position: sticky;
      //bottom: 0;
      background-color: white;
    }
  }
}
</style>
