<template>
   <el-row :gutter="20" class="header">
      <div class="header_left">
         <!-- 添加 -->
         <el-button :class="theme === 'white' ? 'menu-white' : 'menu-black'"
            :disabled="hasAuthority(tableMenuAuthorityAdd) == true ? true : delBtnStatus > 0 ? true : false" type="success"
            :icon="DocumentAdd" native-type="button" style="width: 60px" v-DB:200="() => handleAddOrEditDialogValue()">
            添加
         </el-button>
         <el-button :class="theme === 'white' ? 'menu-white' : 'menu-black'" type="danger" :icon="Delete"
            v-DB:200="() => handleDelete(null)" native-type="button" style="width: 90px"
            :disabled="hasAuthority(tableMenuAuthorityDelete) == true ? true : delBtnStatus > 0 ? false : true">
            批量删除
         </el-button>
         <!-- 另存为 -->
         <el-button :class="theme === 'white' ? 'menu-white' : 'menu-black'" type="primary" :icon="Edit"
            native-type="button" style="width: 70px" v-DB:200="() => handleSaveAs()"
            :disabled="hasAuthority(tableMenuAuthorityAdd) == true ? true : delBtnStatus == 1 ? false : true">
            另存为
         </el-button>
         <!-- 刷新 -->
         <el-button :class="theme === 'white' ? 'menu-white' : 'menu-black'" type="danger" :icon="RefreshRight"
            native-type="button" style="width: 60px" :disabled="hasAuthority(tableMenuAuthorityQuery)"
            v-DB:500="() => handleResetTableList()">
            刷新
         </el-button>
      </div>
      <!--右侧-->
      <div class="header_right">
         <el-button :class="theme === 'white' ? 'menu-white searchBarShow' : 'menu-black'" type="success" :icon="Search"
            native-type="button" title="展开/隐藏搜索框" @click="handleChangeSearchBar()"
            style="width: 30px; box-shadow: 2px 2px #ccc; border-radius: 100px">
         </el-button>
         <el-button :class="theme === 'white' ? 'menu-white searchBarShow' : 'menu-black'" native-type="button"
            :icon="Iphone" v-DB:200="() => handleResetAppTableList()" title="适配手机端" style="width: 30px; box-shadow: 2px 2px #ccc; border-radius: 100px ;color: white;
                 background-color: #72b0f1;border: var(--active-background-color-theme-white)">
         </el-button>
         <el-button :class="theme === 'white' ? 'menu-white searchBarShow' : 'menu-black'" native-type="button" :icon="List"
            type="warning" :disabled="hasAuthority(tableMenuAuthorityQuery)" @click="handleShowTableColumn"
            style="width: 30px;box-shadow: 2px 2px #ccc;border-radius: 100px ;">
         </el-button>
      </div>
   </el-row>

</template>

<script setup>
// 设置权限 匹配查询query字符按
import { Search, Delete, DocumentAdd, Edit, Tools, RefreshRight, List, Phone, Iphone } from '@element-plus/icons-vue'
import requestUtil, { getServerUrl } from "@/util/request";
import { computed, defineEmits, defineProps, ref, watch } from "vue";
import { ElMessage, ElMessageBox } from 'element-plus'
import store from "@/store";

// 添加权限判断
const tableMenuAuthorityAdd = computed(() => {
   if (props.port != null) {
      let newAuthrity = props.port + '/add';
      return newAuthrity.replace(/^\//, '').replace(/\//g, ':');
   } else return ''
})
// 删除权限判断
const tableMenuAuthorityDelete = computed(() => {
   if (props.port != null) {
      let newAuthrity = props.port + '/delete';
      return newAuthrity.replace(/^\//, '').replace(/\//g, ':');
   } else return ''
})
// 刷新权限判断
const tableMenuAuthorityQuery = computed(() => {
   if (props.port != null) {
      let newAuthrity = props.port + '/query';
      return newAuthrity.replace(/^\//, '').replace(/\//g, ':');
   } else return ''
})

const emits = defineEmits(['initTableList', 'add', 'refresh', 'initAppTableList', 'delete', 'changeSearchBar', 'showTableColumn'])

// 添加/编辑
const handleAddOrEditDialogValue = (ID) => {
   emits("add")
}
// 另存为
const handleSaveAs = (ID) => {
   emits("saveAs")
}

let timeId = ref();
// 适配APP
const handleResetAppTableList = async () => {
   // 并发1s
   clearTimeout(timeId);
   timeId = setTimeout(function () {
      emits("refresh", app)
   }, 500);
}
// 刷新按钮
const handleResetTableList = async () => {
   // 并发1s
   clearTimeout(timeId);
   timeId = setTimeout(function () {
      emits("refresh")
   }, 500);
}
// 批量删除
const handleDelete = async (row) => {
   ElMessageBox.confirm(`您确定要执行<span style="color: red;">批量删除</span>操作吗？`, '系统提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
      dangerouslyUseHTMLString: true
   }).then(() => {
      emits("delete")
   }).catch(() => {
   });
}
// 搜索框开关 点击事件
const handleChangeSearchBar = () => {
   emits("changeSearchBar")
}
// 打开table-colume列表
const handleShowTableColumn = () => {
   emits("showTableColumn")
}
const props = defineProps({
   port: {
      type: String,
      default: "",
   },
   delBtnStatus: {
      type: Number,
      default: 0,
      required: true,
   },
})
const theme = ref(store.state.theme)
// 上侧 tab处切换菜单 实时左侧tab更新
watch(
   () => [store.state.theme],
   () => {
      theme.value = store.state.theme;
   }, { deep: true, immediate: true }
)

</script>

<style lang="scss" scoped>
// 搜索栏
.header {
   border-radius: 10px;
   margin: 0px 0px !important;
   border: 1px solid #ccc;
   box-shadow: 1px 1px #ccc;
   box-sizing: border-box;
   padding: 5px 0px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   position: relative;

   //左侧菜单长度居中通过 span 和width 控制
   .header_left {
      margin: 0px 5px;
      padding: 0px 10px;
      width: auto !important;
      box-sizing: border-box;

      .menu-white {
         padding: 0px !important;
         margin: 5px 5px !important;
         white-space: nowrap;
      }

      .menu-black {
         padding: 0px !important;
         margin: 5px 5px !important;
         white-space: nowrap;
         background-color: transparent;
         color: white;
         border: 1px solid white;
      }
   }

   .header_right {
      white-space: nowrap;
      margin: 0px 5px;
      padding: 0px 10px;
      width: auto !important;
      display: flex;
      justify-content: right;
      align-items: center;
      box-sizing: border-box;
      margin-right: 10px;

      .menu-thite {
         padding: 0px !important;
         margin: 5px 5px !important;
         white-space: nowrap;
      }

      .menu-black {
         padding: 0px !important;
         //margin: 5px 5px !important;
         white-space: nowrap;
         background-color: transparent;
         color: white;
         border: 1px solid white;
      }

      .searchBarShow :hover {
         //background-color: yellow;
         color: black;
      }
   }
}
</style>
