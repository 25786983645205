export default {
    mounted(el, binding) {
        const { value, arg = 500 } = binding;

        // 检查传入的值是否为函数，如果不是，给出警告
        if (typeof value !== 'function') {
            console.warn('v-DB 指令需要绑定一个函数');
            return;
        }

        let timer;

        // 防止事件重复绑定，确保移除之前的监听器
        el.removeEventListener('click', el.__debouncedClick);

        // 事件绑定检查，确保点击时才触发
        el.__debouncedClick = function (event) {
            event.preventDefault();  // 阻止默认行为
            event.stopPropagation();  // 阻止事件冒泡

            // 清除前一个定时器，确保只有最后一次点击会触发
            if (timer) clearTimeout(timer);
            timer = setTimeout(() => {
                value(); // 执行传入的函数
            }, arg);
        };
        // 确保事件绑定在正确的 DOM 元素上
        // console.log('绑定事件到 DOM 元素:', el);
        // 添加点击事件监听器
        el.addEventListener('click', el.__debouncedClick);
    },
    unmounted(el) {
        // 移除事件监听器，防止内存泄漏
        el.removeEventListener('click', el.__debouncedClick);
    }
};
