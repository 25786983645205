<template>
  <el-dialog :title="cropperDialogTitle" :width="cropperDialogWidth" center
             style="height: 80%" class="dialog" custom-class="no-header-dialog"
  >
    <div class="body" :style="{'height': bodyH + 'px'}">
      <div class="allImg">
        <div class="left" :style="{'width': leftW + 'px','height': leftH + 'px'}">
          <VueCropper
              ref="cropperRef"
              :key="componentKey"
              :img="props.cropperImageSrc"
              :outputSize="option.outputSize"
              :outputType="option.outputType"
              :canScale='option.canScale'
              :autoCrop='option.autoCrop'
              :autoCropWidth='option.autoCropWidth'
              :autoCropHeight='option.autoCropHeight'
              :canMoveBox='option.canMoveBox'
              :canMove='option.canMove'
              :centerBox='option.centerBox'
              :info='option.info'
              :fixed="option.fixed"
              :fixedBox='option.fixedBox'
              :fixedNumber="option.fixedNumber"
              :height="option.height"
              :original="option.original"
              @realTime="realTime"
              @imgLoad="imgLoad"
          >
          </VueCropper>
        </div>
        <div class="right">
          <div class="right_text">
            预览图 (点击查看大图）
          </div>
          <div class="imageMax">
            <div class="avatarContainer">
              <el-image
                  class="avatarImg"
                  :src="Preview"
                  :preview-src-list="getCurrentUrl()"
                  fit="contain">
              </el-image>
            </div>
          </div>
          <div style="display: flex;justify-content: center;margin: 10px">
            <el-button style="" class="action_button" size="mini" type="danger" plain icon="el-icon-zoom-in"
                       v-DB:500="handleCropperPicture()">提交
            </el-button>
          </div>
        </div>
      </div>
      <div class="action">
        <el-button class="action_button" size="mini" type="success" plain icon="el-icon-zoom-in"
                   v-DB:500="changeScale(1)">
          放大
        </el-button>
        <el-button class="action_button" size="mini" type="success" plain icon="el-icon-zoom-out"
                   v-DB:500="changeScale(-1)">缩小
        </el-button>
        <el-button class="action_button" size="mini" type="primary" plain v-DB:500="rotateLeft">↺ 左旋转</el-button>
        <el-button class="action_button" size="mini" type="primary" plain v-DB:500="rotateRight">↻ 右旋转</el-button>
        <el-button class="action_button" size="mini" type="primary" plain v-DB:500="resetSizeW">16:9 横</el-button>
        <el-button class="action_button" size="mini" type="primary" plain v-DB:500="resetSizeH">16:9 竖</el-button>
        <el-button class="action_button" size="mini" type="primary" plain v-DB:500="resetSizeBasic">1:1</el-button>
      </div>
    </div>

  </el-dialog>
</template>

<script setup>
import {defineEmits, defineProps, ref, watch, onMounted, reactive, nextTick, watchEffect, computed} from "vue"
import requestUtil, {getServerUrl} from "@/util/request";
import {ElMessage} from 'element-plus'
import store from "@/store";

const componentKey = ref(0);
const getCurrentUrl = function () {
  return [Preview.value];
}
const emits = defineEmits(['handleReplaceImg'])
const option = ref({
  img: '',
  outputSize: 1,       //裁剪生成图片的质量(可选0.1 - 1)
  outputType: 'png',  //裁剪生成图片的格式（jpeg || png || webp）
  info: true,          //图片大小信息
  canScale: false,      //图片是否允许滚轮缩放
  autoCrop: true,      //是否默认生成截图框
  fixed: true,         //是否开启截图框宽高固定比例
  fixedNumber: [1, 1], //截图框的宽高比例, 开启fixed生效
  full: false,         //false按原比例裁切图片，不失真
  autoCropWidth: 500,  //默认生成截图框宽度
  autoCropHeight: 500, //默认生成截图框高度
  fixedBox: false,      //固定截图框是否允许改变
  canMove: true,      //上传图片是否可以移动
  canMoveBox: true,    //截图框能否拖动
  original: false,     //上传图片按照原始比例渲染
  centerBox: true,    //截图框是否被限制在图片里面
  height: true,        //是否按照设备的dpr 输出等比例图片
  infoTrue: false,     //true为展示真实输出图片宽高，false展示看到的截图框宽高
  maxImgSize: 10000,    //限制图片最大宽度和高度
  limitMinSize: 50,      //裁剪框限制最小区域
  enlarge: 1,          //图片根据截图框输出比例倍数
  fillColor: 'white',    // 导出时背景颜色填充
  // mode: '150px 150px'  //图片默认渲染方式
})


const basicImg = ref()
const Preview = ref()

const cropperRef = ref()
const previewsImgStyle = ref()
const previewsDiv = ref()
const previewsBasicDiv = ref()
const previews = ref();
const previewsW = ref();
const previewsH = ref();

let timeId = ref()
// 预览
const realTime = (data) => {
  basicImg.value = data;
  clearTimeout(timeId);
  timeId = setTimeout(function () {
    cropperRef.value.getCropData(data => {
      Preview.value = data
    })
  }, 500)
}
// 裁剪提交
const handleCropperPicture = function () {
  cropperRef.value.getCropData(data => {
    cropperRef.value.getCropBlob(f => {
      const file = f;
      // console.log(file.size)
      emits('handleReplaceImg', data, file)
      componentKey.value++;
    })
  })
}
// 向左边旋转90度
const rotateLeft = function () {
  cropperRef.value.rotateLeft()
}
// 向右边旋转90度
const rotateRight = function () {
  cropperRef.value.rotateRight()
}
// 尺寸大小16：9 横
const resetSizeW = function () {
  componentKey.value++;
  option.value.fixedNumber = [16, 9];
}

// 尺寸大小16：9 横
const resetSizeH = function () {
  componentKey.value++;
  option.value.fixedNumber = [9, 16];
}
// 尺寸大小1:1
const resetSizeBasic = function () {
  componentKey.value++;
  option.value.fixedNumber = [1, 1];
}
// 放大或缩小
const changeScale = (value) => {
  cropperRef.value.changeScale(value)
}

// 检测弹窗是否打开
watch(
    () => [props.cropperDialogVisible],
    () => {
      // 判断屏幕宽度 自适应调整预览图宽度高度
      if (props.cropperDialogVisible == true) {
        setPreviewWidth()
      }
    }
)

// 屏幕宽度检测
window.onresize = () => {
  clearTimeout(timeId);
  timeId = setTimeout(function () {
    setPreviewWidth()
    option.value.centerBox = false;
  }, 1000);
};

const screenWidth = ref()
const imgW = ref(200)
const imgH = ref(200)
const leftW = ref(600);
const leftH = ref(400);
const bodyH = ref(400);
const setPreviewWidth = () => {
  screenWidth.value = window.innerWidth;
  if (screenWidth.value < 800) {
    option.value.autoCropWidth = 200
    option.value.autoCropHeight = 200
  } else if (screenWidth.value >= 800) {
    option.value.autoCropWidth = 500
    option.value.autoCropHeight = 500
  }
  if (basicImg.value) {
    leftW.value = basicImg.value.img.width;
    leftH.value = basicImg.value.img.height;
  }
  imgW.value = screenWidth.value * 0.8 * 0.3;
  imgH.value = screenWidth.value * 0.8 * 0.3;
  // 查看当前屏幕 以及ialog的高度
  bodyH.value = window.innerHeight * 0.8 * 0.8;
}
//父组件传过来三个参数
const props = defineProps(
    {
      cropperDialogTitle: {
        type: String,
        default: '',
        required: true
      },
      cropperDialogVisible: {
        type: Boolean,
        default: false,
        required: true
      },
      cropperDialogWidth: {
        type: String,
        default: '70%',
        required: true
      },
      cropperImageSrc: {
        type: String,
        required: true
      },
    }
)

</script>

<style lang="scss">
.no-header-dialog {
  .el-dialog__header {
    padding: 5px;
  }

  .el-dialog__body {
    padding: 5px !important;
  }
}

.dialog {
  overflow: auto;

  .body {
    height: 400px;
    //border: 1px solid red;
    overflow: auto;

    .allImg {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .left {
        margin: 10px;
        white-space: normal;
        width: 600px;
        height: 400px;
        display: flex;
        align-items: center;
        justify-content: left;
      }

      .right {
        margin: 10px;
        white-space: normal;

        .right_text {
          color: red;
          text-align: center;
          margin: 20px;
        }

        .imageMax {
          max-width: 200px !important;
          max-height: 200px !important;
          // 图片大小锁住尺寸
          .avatarContainer {
            width: 200px; /* 设置容器的宽度，可以根据需要调整 */
            height: 200px; /* 设置容器的高度，可以根据需要调整 */
            display: flex;
            justify-content: center;
            align-items: center;
          }

          .avatarImg {
            width: 100%;
            height: 100%;
          }

        }

      }
    }

    .action {
      margin: 0 20px 0 20px;

      .action_button {
        margin: 5px;
        white-space: nowrap;
      }
    }
  }
}

//:deep .el-image__inner{
//  max-width: 200px !important;
//  max-height: 200px !important;
//}

</style>
