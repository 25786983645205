import {ElMessage} from "element-plus";
import store from "@/store";

const keyString = 'Acousma123456789';
const ivString = 'Acousma123456789';

// 解密
export async function decryptData(encryptedData) {
    const key = new TextEncoder().encode(keyString);
    const iv = new TextEncoder().encode(ivString);
    if (isString(encryptedData) || isValidBase64(encryptedData)) {
        try {
            const encryptedDataArray = Uint8Array.from(atob(encryptedData), c => c.charCodeAt(0));
            const keyObj = await window.crypto.subtle.importKey(
                "raw",
                key,
                {name: "AES-CBC"},
                false,
                ["decrypt"]
            );
            const decryptedData = await window.crypto.subtle.decrypt(
                {name: "AES-CBC", iv: iv},
                keyObj,
                encryptedDataArray
            );
            return new TextDecoder().decode(decryptedData);
        } catch (e) {
            initErrorToClear()
            // router.replace("/login")
            // console.error("解密错误: ", e);
            // 可以在这里处理特定的解密错误或返回null
        }
    } else {
        // console.error("非法的Base64字符串: ", encryptedData);
        // 可以在这里处理Base64字符串错误或返回null
    }
    return null;
}

// 加密
export async function encryptData(dataToEncrypt) {
    const key = new TextEncoder().encode(keyString);
    const iv = new TextEncoder().encode(ivString);
    if (isString(dataToEncrypt) || isValidBase64(dataToEncrypt)) {
        try {
            // 将字符串数据转换为ArrayBuffer
            const dataArray = new TextEncoder().encode(dataToEncrypt);
            // 导入密钥
            const keyObj = await window.crypto.subtle.importKey(
                "raw",
                key,
                {name: "AES-CBC"},
                false,
                ["encrypt"]
            );
            // 加密数据
            const encryptedData = await window.crypto.subtle.encrypt(
                {name: "AES-CBC", iv: iv},
                keyObj,
                dataArray
            );
            // 将加密后的数据转换为Base64字符串
            return window.btoa(String.fromCharCode.apply(null, new Uint8Array(encryptedData)));
        } catch (e) {
            // console.error("加密错误: ", e);
            initErrorToClear()
            // router.replace("/login")
            // 可以在这里处理特定的加密错误或返回null
        }
    }
    return null;
}


function isValidBase64(str) {
    if (typeof str !== 'string') {
        return false;
    }
    const notBase64 = /[^A-Z0-9+\/=]/i;
    const base64Length = str.length - (str.endsWith("==") ? 2 : str.endsWith("=") ? 1 : 0);
    return !notBase64.test(str) && !(base64Length % 4);
}

function isString(value) {
    return typeof value === 'string' || value instanceof String;
}

const initErrorToClear = () => {
    ElMessage.error("请勿篡改数据")
    store.commit('SET_SESSION_TOKEN', ""); // token双端同时制空
    store.commit('SET_USERAUTHORITY', "");  // 用户权限双端同时制空
    store.commit('SET_MENULIST', "");  // 用户菜单双端同时制空
    store.commit('SET_USERINFO', {});  // 用户信息双端同时制空
    store.commit('SET_ROUTES_STATE', false);  // 关闭路由
    store.commit('RESET_TABS'); // 回归导航栏
    store.dispatch('logout');// 清空双端session
}
