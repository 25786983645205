import axios from "axios";
import store from "@/store";
import { ref } from "vue";
import { decryptData } from "@/util/decryptData";

let baseUrl = "https://chenzhiyan.com:5202";
// let baseUrl = "http://localhost:80";

let iamgeUrl = baseUrl + "/image/sysResourceImage/";
let videoUrl = baseUrl + "/video/sysResourceVideo/";
let audioUrl = baseUrl + "/audio/sysResourceAudio/";

// 创建axios实例
const httpService = axios.create({
  baseURL: baseUrl,
  timeout: 5000,
});

/**
 * 添加请求和响应拦截器
 */
// 添加请求拦截器 request
httpService.interceptors.request.use(
  async function (config) {
    // console.log(store.getters.GET_TOKEN)
    // console.log(await decryptData(sessionStorage.getItem("token")))
    if (sessionStorage.getItem("token") != null) {
      config.headers.token = await decryptData(sessionStorage.getItem("token"));
    } else if (store.state.token != null) {
      config.headers.token = await decryptData(store.state.token);
    }
    return config;
  },
  function (error) {
    // 请求错误处理
    return Promise.reject(error);
  }
);

// 添加响应拦截器
httpService.interceptors.response.use(
  function (response) {
    // 响应数据处理
    return response;
  },
  function (error) {
    // 响应错误处理
    return Promise.reject(error);
  }
);

/*网络请求部分*/

/*
 *  get请求
 *  url:请求地址
 *  params:参数
 * */
export function get(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: "get",
      params: params,
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  post请求
 *  url:请求地址
 *  params:参数
 * */
export function post(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: "post",
      data: params,
    })
      .then((response) => {
        // console.log(response)
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/*
 *  文件上传
 *  url:请求地址
 *  params:参数
 * */
export function fileUpload(url, params = {}) {
  return new Promise((resolve, reject) => {
    httpService({
      url: url,
      method: "post",
      data: params,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        reject(error);
      });
  });
}

/**
 * 获取请求地址
 */
export function getServerUrl() {
  return baseUrl;
}

export function getTemImageUrl() {
  return temIamgeUrl;
}

export function getImageUrl() {
  return iamgeUrl;
}

export function getTemVideoUrl() {
  return temVideoUrl;
}

export function getVideoUrl() {
  return videoUrl;
}

export function getAudioUrl() {
  return audioUrl;
}

/*
 *  延迟请求
 *  time:延迟时间
 *  callback:方法
 */
let timeId = ref();

export function WaitTime(time, callback) {
  clearTimeout(timeId);
  timeId = setTimeout(function () {
    callback();
  }, time);
}

export default {
  get,
  post,
  fileUpload,
  getServerUrl,
  getTemImageUrl,
  getImageUrl,
  getTemVideoUrl,
  getVideoUrl,
  getAudioUrl,
  WaitTime,
};
